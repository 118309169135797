import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  updateByKey as updateByKeyAction,
  getIntervalList as getIntervalListAction,
  updateInterval as updateIntervalAction,
  addInterval as addIntervalAction,
} from "../../redux/modules/settings";

import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";
import UiSelectDates from "../../components/select/UiSelectDates";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiInputTimeRange from "../../components/inputs/UiInputTimeRange";

import UiSwitch from "../../components/switch/UiSwitch";

import { formatDateYMD, formatDatePlusDayYMD } from "../../utilities/date";

import "./SettingStyle.scss";

function SettingPage(props) {
  const intervalTitles = [
    { sort: true, title: "Интервал" },
    { sort: false, title: "Название" },
    { sort: false, title: "Цена" },
    { sort: false, title: "Время до включения (мин)" },
    { sort: false, title: "Бесплатно в городе" },
    { sort: false, title: "Скрыть" },
    { sort: false, title: "Управление" },
  ];

  const [intervalList0, setIntervalList0] = useState([]);
  const [intervalList1, setIntervalList1] = useState([]);
  const [intervalList2, setIntervalList2] = useState([]);
  const [intervalList3, setIntervalList3] = useState([]);
  const [intervalList4, setIntervalList4] = useState([]);
  const [intervalList5, setIntervalList5] = useState([]);
  const [intervalList6, setIntervalList6] = useState([]);

  const [date, setDate] = useState(new Date());

  const [intervalTime, setIntervalTime] = useState("");
  const [intervalLimit, setIntervalLimit] = useState(0);

  useEffect(() => {
    if (props.login.user) props.getSettingList();
    props.getIntervalList(formatDateYMD(new Date(date), "-"));
  }, [props.login.user]);

  useEffect(() => {
    var arr0 = [], arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [];

    props.settings.listInterval.map((item, index) => {
      let listItem = [
        {
          title: intervalTitles[0].title,
          text: item.title,
        },

        {
          text: item.name,
          input: item.name == null ? ` ` : `${item.name}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.name = val;
            props.updateInterval(_item).then(() =>
              props.getIntervalList(formatDateYMD(new Date(date), "-"))
            );
          },

        },

        {
          text: item.price,
          input: item.price == null ? ` ` : `${item.price}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.price = parseInt(val);
            props.updateInterval(_item).then(() =>
              props.getIntervalList(formatDateYMD(new Date(date), "-"))
            );
          },

        },


        {
          text: item.time_offset,
          input: `${item.time_offset}`,
          isText: true,
          onBlurInput: (val) => {
            let _item = item;
            _item.time_offset = parseInt(val);
            props.updateInterval(_item).then(() =>
              props.getIntervalList(formatDateYMD(new Date(date), "-"))
            );
          },

        },

        {
          button: item.is_free_for_city == 0 ? "eye" : "eye-open",
          color: item.is_free_for_city == 1 ? "green" : "black",
          onClick: () => {
            if (window.confirm(` Сделать бесплатно в городе ?`)) {
              let _item = item;
              _item.is_free_for_city = item.is_free_for_city == 1 ? 0 : 1;
              props
                .updateInterval(_item)
                .then(() =>
                  props.getIntervalList(formatDateYMD(new Date(date), "-"))
                );
            }
          },
        },


        {
          button: "eye",
          color: item.is_active == 0 ? "red" : "black",
          onClick: () => {
            if (window.confirm(`${item.is_active == 0 ? `Открыть` : `Скрыть`} элемент ?`)) {
              let _item = item;
              _item.is_active = item.is_active == 1 ? 0 : 1;
              props
                .updateInterval(_item)
                .then(() =>
                  props.getIntervalList(formatDateYMD(new Date(date), "-"))
                );
            }
          },
        },
        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props
                .updateInterval(_item)
                .then(() =>
                  props.getIntervalList(formatDateYMD(new Date(date), "-"))
                );
            }
          },
        },
      ];
      if (item.type == 0) arr0.push(listItem);
      if (item.type == 1) arr1.push(listItem);
      if (item.type == 2) arr2.push(listItem);
      if (item.type == 3) arr3.push(listItem);
      if (item.type == 4) arr4.push(listItem);
      if (item.type == 5) arr5.push(listItem);
      if (item.type == 6) arr6.push(listItem);

    });
    setIntervalList0(arr0);
    setIntervalList1(arr1);
    setIntervalList2(arr2);
    setIntervalList3(arr3);
    setIntervalList4(arr4);
    setIntervalList5(arr5);
    setIntervalList6(arr6);

  }, [props.settings.listInterval]);

  let list = props.settings.list
    .filter((it) => {
      return it.show_in_list == 0;
    })
    .map((item, index) => {
      if (item.is_switch == 1) {
        return (
          <UiSwitch
            key={index}
            label={`${item.description}`}
            value={parseInt(item.value) === 0 ? false : true}
            onChange={(val) =>
              props.updateByKey(item.title, val == true ? 1 : 0)
            }
          />
        );
      } else {
        return (
          <UiInputText
            key={index}
            placeholder={item.value}
            label={`${item.description} (Если стоить 0 значит ВЫКЛЮЧЕНО )`}
            onBlur={(val) => {
              if (val != "") props.updateByKey(item.title, val);
            }}
          />
        );
      }
    });

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки интервала" />
        </div>
      </section>
      <section className="page-content">
        <p>Дата интервала</p>
        <UiSelectDates
          onChange={(date) => {
            setDate(date);
            props.getIntervalList(formatDateYMD(new Date(date), "-"));
          }}
          date={date}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList0}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList1}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList2}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList3}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList4}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList5}
        />

        <UiTableAdaptive
          pageSize={111}
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList6}
        />


        <br />

        <p>Добавить интервала</p>

        <UiInputTimeRange
          label="Интервал"
          onChange={(val) => setIntervalTime(val)}
        />
        <UiInputText
          label={`Лимит`}
          onChange={(val) => setIntervalLimit(val)}
        />
        <UiButtonColor
          color="indigo"
          text="Добавить интервал"
          small={true}
          onClick={() => {
            console.log(intervalTime.split(" - "));
            if (intervalTime.length == 13 && intervalLimit > 0) {
              props
                .addInterval({
                  title: intervalTime,
                  date: formatDateYMD(new Date(date), "-"),
                  order_limit: intervalLimit,
                })
                .then(() => {
                  props.getIntervalList(formatDateYMD(new Date(date), "-"));
                });
            } else {
              alert("Выберете интервал и лимит");
            }
          }}
        />
      </section>

      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки праздника" />
        </div>
      </section>

      <section className="page-content">
        <p>Дата начала праздника</p>
        <UiSelectDates
          onChange={(date) => {
            props.updateByKey(
              "start_wedding_date",
              formatDateYMD(new Date(date), "-")
            );
          }}
          date={new Date(props.getSettingValue("start_wedding_date"))}
        />
        <p>Дата окончания праздника</p>
        <UiSelectDates
          onChange={(date) => {
            props.updateByKey(
              "end_wedding_date",
              formatDateYMD(new Date(date), "-")
            );
          }}
          date={new Date(props.getSettingValue("end_wedding_date"))}
        />
        <UiInputText
          label={`Минимальная сумма корзины для бесплатной доставки в праздник`}
          value={props.getSettingValue(
            "min_wedding_basket_sum_to_free_delivery"
          )}
          onChange={(val) =>
            props.updateByKey("min_wedding_basket_sum_to_free_delivery", val)
          }
        />

        <UiInputText
          label={`Минимальная Сумма для доставки`}
          value={props.getSettingValue("min_sum_to_delivery_wedding")}
          onChange={(val) =>
            props.updateByKey("min_sum_to_delivery_wedding", val)
          }
        />

        <UiInputText
          label={`Мин. сумма для самовывоза`}
          value={props.getSettingValue("min_sum_to_self_delivery_wedding")}
          onChange={(val) =>
            props.updateByKey("min_sum_to_self_delivery_wedding", val)
          }
        />
      </section>

      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>
      <section className="page-content">{list}</section>
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getSettingList: getSettingListAction,
  getSettingValue: getSettingValueAction,
  updateByKey: updateByKeyAction,
  getIntervalList: getIntervalListAction,
  updateInterval: updateIntervalAction,
  addInterval: addIntervalAction,
})(SettingPage);
