
import {
  getList, 
  makeMenu,
  updateMenu, 
} from "../../services/SiteMenu";

 

const moduleName = "siteMenu";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_FILTERED_LIST = `${moduleName}/SET_FILTERED_LIST`; 
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
  list: [],
 
  filteredList: [],
  error: false,
  errorType: 3,
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      return { ...state, list: payload };
    case SET_FILTERED_LIST:
      return { ...state, filteredList: payload };
 
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_ERROR_TYPE:
      return { ...state, errorType: payload };

    case SET_API_REQUEST:
      console.log("fetching API request", payload);
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const setList = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LIST,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};
 

export const getMenuList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getList().then((res) => {
      if (res.response) {
        let arr = [],
          arr2 = [[]],
          arrResult = [];
        res.response.map((item) => {
          item.list = [];
          //|| res.response.filter((fi) => fi.root_menu_id == item.id ).length == 0
          if (item.root_menu_id == null  ) arr.push(item);

          //if (res.response.filter((fi) => fi.root_menu_id == item.id && fi.root_menu_id != item.id ).length == 0 )  arr.push(item);

          /*
              let f = false;
          res.response.map((item2) => {
            if(item.root_menu_id == item2.id) f = true;
          });
          if(!f) arr.push(item);
          */
        });

        res.response.map((item) => {
          if (item.root_menu_id != null) {
            if (arr2[item.root_menu_id]) {
              arr2[item.root_menu_id].push(item);
            } else {
              arr2[item.root_menu_id] = [];
              arr2[item.root_menu_id].push(item);
            }
          }  else {
            arr2[0].push(item);
          }
        });
        arr.map((item) => {
          arrResult.push(item);
          if (arr2[item.id]) {
            arr2[item.id].map((item2) => {
              arrResult.push(item2);
            });
          }
        }); 

        let filteredList  = [];
        let tempArr =  res.response;
        res.response.map((item) => {
          let f = null;
          tempArr.map((item2, index) => {
            if(item.id == item2.root_menu_id) {
              item.list.push(item2);
              f = index; 
            }
          }); 
        });

 

        dispatch({
          type: SET_FILTERED_LIST,
          payload: arr,
        });


        dispatch({
          type: SET_LIST,
          payload: arrResult,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

 

export const addMenu = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return makeMenu(getState().login.user.api_token, _data).then((res) => {
      console.log(res);
      if (res.response) {
        dispatch(getMenuList());
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const update = (_data, isUpdate = true) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return updateMenu(getState().login.user.api_token, _data).then(
      (res) => {
        console.log(res);
        if (res.response) {
          if(isUpdate) dispatch(getMenuList());
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      }
    );
  } catch (error) {
    console.error(error);
  }
};
 