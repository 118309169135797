import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import UiInputText from "../../components/inputs/UiInputText";
import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiSelect from "../../components/select/UiSelect";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiSearchDropDown from "../../components/inputs/UiSearchDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiButtonColor from "../../components/button/UiButtonColor";

import {
    searchStorage as searchStorageAction,
} from "../../redux/modules/storage";
import { getCategoryList as getCategoryListAction } from "../../redux/modules/category";
import {
    add as addAction,
    getCategoriesAdditional as getCategoriesAdditionalAction,
    updateItem as updateItemAction,
    addItem as addItemAction,
    update as updateAction,
} from "../../redux/modules/categoryAdditional";
import {
    convertImages,
    inArrayValueByKey,
    toArrayLabelListKey,
} from "../../utilities/array";

import Env from "../../services/Env";

import "./HomeStaticStyle.scss";



function HomeStaticEditPage(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [order, setOrder] = useState({
        isEdit: false,

        title: "",
        text: "",
        sale_type: "fixed",
        category_id: 0,
       
        storages_list: [],
    });
    const [searchLine, setSearchLine] = useState("");
    const [itemData, setItemData] = useState(null);
    const [categoriesList, setCategoriesList] = useState([]);
    const [storageList, setStorageList] = useState([]);
    const [tableListStorages, setTableListStorages] = useState([]);
    const [tableSelectedListStorages, setSelectedTableListStorages] = useState([]);


    const storagesTitles = [
        { sort: false, title: "" },
        { sort: false, title: "" },
        { sort: true, title: "Название" },
        { sort: true, title: "Цена" },
        { sort: false, title: "Остаток" },
    ];

    useEffect(() => {
        props.getCategoriesAdditional(id);
        props.getCategoryList().then((data) => {
            data.unshift({ id: null, title: "" });
            setCategoriesList(data);
        });
        window?.scrollTo(0, 0);
    }, [id]);


    useEffect(() => {

        if (props.categoryAdditional.additionalItem) {

            let _list = props.categoryAdditional.additionalItem.storages_list.list;
            _list.map((item) => {
                props.categoryAdditional.additionalItem.storages_list.list_id.map((item2) => {
                    if (item2.storage_id == item.id) {
                        item.sale_item = item2;
                    }
                })
            })
            setOrder({
                isEdit: false,
                id: props.categoryAdditional.additionalItem.data.id,
                title: props.categoryAdditional.additionalItem.data.title,
                text: props.categoryAdditional.additionalItem.data.text,

                is_disable: props.categoryAdditional.additionalItem.data.is_disable,
                is_del: props.categoryAdditional.additionalItem.data.is_del,
                storages_list: _list,
            });

        }

    }, [props.categoryAdditional.additionalItem]);




    useEffect(() => {
        if (searchLine != "") {
            _search(searchLine);
        } else {

        }
    }, [searchLine]);

    useEffect(() => {
        let arr = [];
        storageList.map((item) => {
            let images = convertImages(item.imgs);
            item.images = images;
            arr.push([
                {
                    button: "check",
                    onClick: () => {
                        props.addItem({
                            storage_id: item.id,
                            sale_id: id,
                        })

                    },
                },
                { image: images[0] ? Env.PUBLIC_URL + images[0] : null },
                { text: item.title },
                { text: `${item.price} ₽` },
                { text: `${item.storage_left} шт` },

            ]);
        });
        setTableListStorages(arr);
    }, [storageList]);

    useEffect(() => {
        let arr = [];
        order.storages_list.map((item) => {
            let images = convertImages(item.imgs);
            item.images = images;
            arr.push([
                {
                    button: "check",
                    color: "green",
                    onClick: () => {
                        let _item = item.sale_item;
                        _item.is_del = 1;
                        props.updateItem(_item).then(() => {

                        })
                    },
                },
                { image: images[0] ? Env.PUBLIC_URL + images[0] : null },
                { text: item.title },
                { text: `${item.price} ₽` },
                { text: `${item.storage_left} шт` },

            ]);
        });
        setSelectedTableListStorages(arr);
    }, [order.storages_list]);


    /**
     *
     * @param {string} key
     * @param {any} val
     */
    function updateOrderObj(key, val) {
        let _obj = order;
        _obj[key] = val;
        setOrder({ ..._obj });
    }

    function _search(_searchLine) {
        setSearchLine(_searchLine);
        props.searchStorage(_searchLine).then((data) => {
            if (data) setStorageList(data);
        });
    }


    return (
        <div id="page" className="product-add-page">
            <div className="product-add-wrap">
                <div className="product-add-about add">
                    <div className="product-add-about-title">
                        <div className="product-add-about-title-wrap">
                            <h3>Добавить доп</h3>
                        </div>
                    </div>
                    <div className="product-add-notes">
                        <div className="product-add-notes-list-wrap">
                            <div className="product-add-info">
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Название акции:"
                                        placeholder="Название"
                                        value={order.title}
                                        onChange={(val) => {
                                            updateOrderObj("title", val);
                                        }}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiTextArea
                                        label="Краткое описание"
                                        value={order.text}
                                        onChange={(val) => updateOrderObj("text", val)} />
                                </div>


                                <div className="product-add-info-form">
                                    <h3>Для какой категории допы</h3>
                                    <UiSearchDropDown
                                        value={inArrayValueByKey(toArrayLabelListKey(categoriesList, "title"), "value", order.category_id)}
                                        items={toArrayLabelListKey(categoriesList, "title")}
                                        sort={true}
                                        onChange={(val) => {
                                            updateOrderObj(
                                                "category_id",
                                                val
                                            );
                                        }}
                                    />
                                </div>


                                <div className="product-add-info-form">
                                    <h3>Товары для рекомендации</h3>
                                    {tableSelectedListStorages.length > 0 && <UiTableAdaptive
                                        pageSize={20}
                                        titleList={storagesTitles}
                                        grid={storagesTitles.length}
                                        tableList={tableSelectedListStorages}
                                    />}

                                </div>


                                <div className="page-search">
                                    <UiInputSearch
                                        label="Поиск товаров:"
                                        placeholder="Поиск"
                                        value={searchLine}
                                        onChange={(val) => setSearchLine(val)}
                                    />
                                </div>



                                <div className="product-add-info-form">
                                    <UiTableAdaptive
                                        pageSize={20}
                                        titleList={storagesTitles}
                                        grid={storagesTitles.length}
                                        tableList={tableListStorages}
                                    />

                                </div>




                                <div className="product-add-info-form product-add-info-photo">
                                    <UiButtonColor
                                        color="indigo"
                                        text={"Обновить"}
                                        small={true}
                                        onClick={() => {
                                            if (
                                                order.title != "" &&
                                                order.text != "" &&
                                                order.storages_list.length > 0
                                            ) {
                                                let _order = order;
                                                props.update(_order).then((res) => {
                                                    if (res) navigate(`/products/additional/list`);
                                                });


                                            } else {
                                                alert("Заполните поля название и описание");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
}

export default connect(
    ({ categoryAdditional, material }) => ({ categoryAdditional, material }),
    {
        updateItem: updateItemAction,
        update: updateAction,
        getCategoriesAdditional: getCategoriesAdditionalAction,
        getCategoryList: getCategoryListAction,
        searchStorage: searchStorageAction,
        addItem: addItemAction,
    }
)(HomeStaticEditPage);
