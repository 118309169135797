export default {
  SIZE: [
    { value: null, text: "" },
    { value: "s", text: "S" },
    { value: "m", text: "M" },
    { value: "l", text: "L" },
    { value: "xl", text: "XL" },
  ],
  OCCASION: [
    { value: null, text: "" },
    { value: 0, text: "Свидание" },
    { value: 1, text: "День рождения" },
    { value: 2, text: "Юбилей" },
    { value: 3, text: "Годовщина свадьбы" },
    { value: 4, text: "Выписка - Девочка" },
    { value: 5, text: "Выписка - Мальчик" },
  ],
  COLORS: [
    { value: null, text: "" },
    { value: 0, text: "Красный", color: "#ff0000" },
    { value: 1, text: "Нежный", color: "#fed6bc" },
    { value: 2, text: "Зеленый", color: "#2bba2b" },
    { value: 3, text: "Розовый", color: "#f544b1" },
    { value: 4, text: "Кремовый", color: "#fdddab" },
    { value: 5, text: "Жёлтый", color: "#f8e206" },
    { value: 6, text: "Оранжевый", color: "#ff8000" },
    { value: 7, text: "Синий", color: "#0000ff" },
    { value: 8, text: "Фиолетовый", color: "#8000ff" },
    { value: 9, text: "Белый", color: "#cfcfcf" },
    { value: 10, text: "Черный", color: "#000" },
    {
      value: 11,
      text: "Микс",
      color: "rgba(56,167,201,1) ",
    },
  ],
  WHOM: [
    { value: null, text: "" },
    { value: 0, text: "Маме" },
    { value: 1, text: "Девушке" },
    { value: 2, text: "Женщине" },
    { value: 3, text: "Мужчине" },
    { value: 4, text: "Ребёнку" },
    { value: 5, text: "Семье" },

    { value: 6, text: "Коллективу" },
    { value: 7, text: "Руководителю - Женщине " },
    { value: 8, text: "Руководителю - Мужчине " },
    { value: 9, text: "Учителю" },
    { value: 10, text: "Классный руководитель" },
  ],
  FLOWERS: [
    { value: null, text: "" },
    { value: "Rozy", text: "Розы" },
    { value: "Roza kustovaya", text: "Роза кустовая" },
    { value: "Roza pionovvaluenaya", text: "Роза пионовидная" },
    { value: "Alstromeriya", text: "Альстромерия" },

    { value: "Anemony", text: "Анемоны" },

    { value: "Giacint", text: "Гиацинт" },
    { value: "Gerbery", text: "Герберы" },
    { value: "Gvozdiki", text: "Гвоздики" },
    { value: "Gortenziya", text: "Гортензия" },
    { value: "Georginy", text: "Георгины" },
    { value: "Gipsofila", text: "Гипсофила" },
    { value: "Gladiolusy", text: "Гладиолусы" },
    { value: "Iris", text: "Ирис" },
    { value: "Kalla", text: "Калла" },

    { value: "Lilii", text: "Лилии" },


    { value: "Mattiola", text: "Маттиола" },
    { value: "Mimozy", text: "Мимозы" },
    { value: "Nartsiss", text: "Нарцисс" },
    { value: "Nutаn", text: "Нутан" },
    { value: "Piony", text: "Пионы" },
    { value: "Podsolnuh", text: "Подсолнух" },
    { value: "Proteya", text: "Протея" },
    { value: "Romashki", text: "Ромашки" },
    { value: "Ranunkulus", text: "Ранункулюс" },
    { value: "Siren", text: "Сирень" },
    { value: "Strelitsiya", text: "Стрелиция" },
    { value: "Tyulpany", text: "Тюльпаны" },

    { value: "Hrizantemy", text: "Хризантемы" },
    { value: "Eustoma", text: "Эустома" },
  ],

  ALLOEWED_FILTER_ARRAY: [
    'cvet',

    'filtr-dlya-kogo',
    'filtr-sobytie',
    'filtr-kolichestvo',
    'filtr-kollekciya',
    'filtr-upakovka',
    'filtr-vysota',
    'shirina-buketa', 

    'dlya-kogo',
    'sobytie',
    'kollekciya',
    'tip-shara',
    'razmery',
    'forma-folgirovannogo-shara',
    'multgeroi',
    'kolichestvo',
    'upakovka',
    'vysota',
    'tip-roz-spravochnik',

    "max_price",
    "min_price"

  ]
};
