import ENV from './Env.js';


export const getList = () => {
  return fetch(`${ENV.API_URL}/category/additional/all`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const getCategoriesAdditionalId = (_id) => {
  return fetch(`${ENV.API_URL}/category/additional/get/id/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}
 

export const makeCategoriesAdditional = (_api, _data) => {
  _data['api_token'] = _api; 
  return fetch( `${ENV.API_URL}/category/additional/store`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateCategoriesAdditional = (_api, _data) => {
  _data['api_token'] = _api;
  return fetch(`${ENV.API_URL}/category/additional/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const updateCategoriesAdditionalItem = (_api, _data) => {
  _data['api_token'] = _api;
  return fetch(`${ENV.API_URL}/category/additional/item/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const addCategoriesAdditionalItem = (_api, _data) => {
  _data['api_token'] = _api;
  return fetch(`${ENV.API_URL}/category/additional/item/store`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


