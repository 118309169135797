import React, { useEffect, useState } from "react";

import UiDropDown from "../inputs/UiDropDown";

import "./UiTreeTable.scss";
import UiIconCategory from "../icons/icon-category";
import UiInputText from "../inputs/UiInputText";


const UiTreeItem = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div className="tree-table-adaptive-item">
      <div className="tree-table-adaptive-item-title">
        <p
          onClick={() => {
            setIsExpand(!isExpand);
            document.querySelector(`.lvl-m-${props.expand}`)?.classList.toggle('hide');
            document.querySelector(`.lvl-${props.expand}`)?.classList.toggle('hide');

          }}
        >{props.expand ? <b> {isExpand ? '➡️' : '⬇️'} </b> : ""}{props.title}</p> 
        <div className="tree-table-adaptive-item-title-control">
          {props.icon && <UiIconCategory icon={props.icon} size={30} />}

          {props.onChangeSort && <UiInputText type="number" value={props.sort} onChange={(v) => props.onChangeSort(v)} />}
          <button onClick={(v) => props.onUrlClick(v)}>www🛡️</button>
          <button onClick={() => props.onEditClick()}>✏️</button>

          {/*
            <button className={props?.is_top_menu == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_top_menu")} >верх меню {props?.is_top_menu == 1 ? '✅' : '🚧'} </button>
         */}

          <button className={props?.is_disable_url == 1 ? 'green-bg' : ``} onClick={() => props.onChangeVal("is_disable_url")} >url {props?.is_disable_url == 1 ? '❗' : '❕'} </button>

          {/*
          {props.onChangeVisible ? 
            <button className={props?.is_menu == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVisible("is_menu_visible")} >меню ⇊</button>
          :
            <button className={props?.is_menu == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_menu")} >меню</button>
          }

          <button className={props?.is_site_menu == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_site_menu")} >главная</button>
          <button className={props?.is_popular == 1 ? 'green-bg' : ``}  onClick={() => props.onChangeVal("is_popular")}   >избранное</button>
          */}

          <button onClick={() => props.onDeleteClick()}>❌</button>
        </div>
      </div>
      {props.list && (
        <div className="tree-table-adaptive-item-list">{props.list}</div>
      )}
    </div>
  );
};


const UiTreeSitemMenuTable = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props.list) setList(props.list);
  }, [props.list]);

  function makePath(item = null, item2 = null, item3 = null, item4 = null) {
    let arr = ["/catalog"];
    if (item) {
      if (item.is_disable_url == 0) arr.push(item.title_url);
    }
    if (item2) {
      if (item2.is_disable_url == 0) arr.push(item2.title_url);
    }
    if (item3) {
      if (item3.is_disable_url == 0) arr.push(item3.title_url);
    }
    if (item4) {
      if (item4.is_disable_url == 0) arr.push(item4.title_url);
    }

    return arr.join("/");
  }


  var treeList = list.map((item, index) => {
    let arrIds = [];
    let list1 = item.list.map((item2, index2) => {
      arrIds.push(item2.id);
      let list2 = item2.list.map((item3, index3) => {
        arrIds.push(item3.id);
        let list3 = item3.list.map((item4, index4) => {
          arrIds.push(item4.id);

          return (
            <div key={index4} className="tree-table-adaptive-wrap lvl4">
              <UiTreeItem
                icon={item4.icon}
                title={item4.title}
                root={item4.root_category_id}

                is_popular={item4.is_popular}
                is_menu={item4.is_menu}
                is_site_menu={item4.is_site_menu}
                is_top_menu={item4.is_top_menu}
                is_disable_url={item4.is_disable_url}

                sort={item4.sort}

                subList={props.subList}
                expand={`lv4${index2}`}

           
                onUrlClick={() => props.onUrlClick( makePath(item,item2,item3,item4) )}

                onEditClick={() => props.onEditClick(item4, makePath(item,item2,item3,item4) )}
                onChangeVal={(v) => props.onChangeVal(v, item4)}
                onDeleteClick={(v) => props.onDeleteClick(item4)}

                onChangeSort={(v) => props.onChangeSort(v, item4)}

              />
            </div>
          );
        });

        return (
          <div key={index3} className={`tree-table-adaptive-wrap lvl3 lvl-lv3${index}${index2}${index3}`}>
            <UiTreeItem
              icon={item3.icon}
              title={item3.title}
              list={list3}
              root={item3.root_category_id}

              sort={item3.sort}

              is_popular={item3.is_popular}
              is_menu={item3.is_menu}
              is_site_menu={item3.is_site_menu}
              is_top_menu={item3.is_top_menu}
              is_disable_url={item3.is_disable_url}

              subList={props.subList}
              expand={`lv3${index}${index2}${index3}`}

              onUrlClick={() => props.onUrlClick( makePath(item,item2,item3 ) )}

              onEditClick={() => props.onEditClick(item3, makePath(item,item2,item3) )}
              onDeleteClick={(v) => props.onDeleteClick(item3)}
              onChangeVal={(v) => props.onChangeVal(v, item3)}

              onChangeSort={(v) => props.onChangeSort(v, item3)}
            />
          </div>
        );
      });

      return (
        <div key={index2} className={`tree-table-adaptive-wrap lvl2 lvl-lv2${index}${index2}`}>
          <UiTreeItem
            icon={item2.icon}
            title={item2.title}

            is_popular={item2.is_popular}
            is_menu={item2.is_menu}
            is_site_menu={item2.is_site_menu}
            is_top_menu={item2.is_top_menu}
            is_disable_url={item2.is_disable_url}

            sort={item2.sort}

            list={list2}
            root={item2.root_category_id}
            subList={props.subList}
            expand={`lv2${index}${index2}`}

            urlPath={makePath(item,item2)}

            onUrlClick={() => props.onUrlClick( makePath(item,item2 ) )}

            onEditClick={() => props.onEditClick(item2, makePath(item,item2 ) )}
            onDeleteClick={(v) => props.onDeleteClick(item2)}
            onChangeVal={(v) => props.onChangeVal(v, item2)}

            onChangeSort={(v) => props.onChangeSort(v, item2)}
          />
        </div>
      );
    });
    return (
      <div key={index} className={`tree-table-adaptive-wrap lvl1 lvl-m-${index}`}>
        <UiTreeItem
          icon={item.icon}
          title={item.title}

          is_popular={item.is_popular}
          is_menu={item.is_menu}
          is_site_menu={item.is_site_menu}
          is_top_menu={item.is_top_menu}
          is_disable_url={item.is_disable_url}

          sort={item.sort}

          list={list1}
          root={item.root_category_id}
          subList={props.subList}
          expand={index}
          
          onUrlClick={() => props.onUrlClick( makePath(item ) )}

          onEditClick={() => props.onEditClick(item, makePath(item) )}
          onDeleteClick={(v) => props.onDeleteClick(item)}
          onChangeVal={(v) => props.onChangeVal(v, item)}
          onChangeSort={(v) => props.onChangeSort(v, item)}
          onChangeVisible={(v) => props.onChangeVisible(arrIds, item)}

        />
      </div>
    );
  });

  return <div className="tree-table-adaptive">{treeList}</div>;
};

export default UiTreeSitemMenuTable;
