import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData, storeData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";

import UiInputText from "../../components/inputs/UiInputText";
import UiIconMenu from "../../components/icons/icon-category";

 
import UiSearchDropDown from "../../components/inputs/UiSearchDropDown"; 
import UiButtonColor from "../../components/button/UiButtonColor";

import {
  getMenuList as getMenuListAction,
  update as updateAction
} from "../../redux/modules/siteMenu";

import {
  inArrayValueByKey,
  toArrayLabelListKey, 
} from "../../utilities/array";

import "./MenuStyle.scss";
import Env from "../../services/Env";


function MenuSiteEditPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState({
    isEdit: false,
    title: "",
    full_title: "",
    description: "",
    category: 0,
    is_menu: 1,
    is_site_menu: 0, 
    root_category_id: 0,
    description: "",
  });

  const [menuList, setMenuList] = useState([]);
  const imgArr = Array(96).fill(0);

  useEffect(() => {
    props.getMenuList().then((data) => {
      if (data) {
        let menuList = data;
        menuList.unshift({
          value: null,
          text: " -- ",
        });
        setMenuList(menuList);
      }
    });

  }, []);



  useEffect(() => {
    let editData = retrieveData("editSitemMenu");
    if (editData) {
      console.log(editData);
      setMenu(editData);
    }



    window?.scrollTo(0, 0);
  }, [id]);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = menu;
    _obj[key] = val;
    setMenu({ ..._obj });
  }





  let iconsList = imgArr.map((item, index) => {
    let _icon = `c_${index < 10 ? "0" : ""}${index + 1}`;
    return (
      <div
        style={menu.icon == _icon ? { border: "2px solid black" } : {}}
        onClick={() => {
          console.log(_icon)
          updateOrderObj("icon", _icon);
        }}
      >
        <UiIconMenu key={index} icon={_icon} size={50} />
      </div>
    );
  });



  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Редактировать пункт меню</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={menu.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>

                <div className="product-add-info-form">

                  {menuList && (
                    <UiSearchDropDown
                      label="Родительский пункт меню:"
                      value={inArrayValueByKey(toArrayLabelListKey(menuList, "title"), "value", menu.root_menu_id)}
                      showEmptyVal
                      items={toArrayLabelListKey(menuList, "title")}
                      onChange={(val) => {

                        updateOrderObj(
                          "root_menu_id",
                          val
                        );
                      }}
                    />

                  )}
                </div>

                <div className="product-add-info-form">
                  <UiInputText
                    label="Статическая ссылка:"
                    placeholder="Ссылка"
                    value={menu.static_link_url}
                    onChange={(val) => {
                      let _val = val.replace(Env.HOST_URL_CATALOG, '');
                      updateOrderObj("static_link_url", _val);
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <div className="product-add-info-icon">{iconsList}</div>
                </div>


                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="primary"
                    text={"Сохранит изменения и Закрыть"}
                    small={true}
                    onClick={() => {
                      if (menu.title != "") {
                        let _item = menu;

                        props.update(_item).then((res) => {
                          if (res) navigate(`/site-menu`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ siteMenu }) => ({ siteMenu }), {
  update: updateAction,
  getMenuList: getMenuListAction,

})(MenuSiteEditPage);
