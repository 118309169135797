import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
 
import { 
  getMenuList as getMenuListAction,
  update as updateAction, 
} from "../../redux/modules/siteMenu";

 
 
import UiButtonColor from "../../components/button/UiButtonColor"; 
import UiPageText from "../../components/text/UiPageText";
 
import { storeData } from "../../services/Storage";
import UiTreeSitemMenuTable from "../../components/table/UiTreeSitemMenuTable";


import "./MenuStyle.scss";

function MenuSiteListPage(props) {
  const navigate = useNavigate();
 
 
  useEffect(() => {
    props.getMenuList(); 
  }, []);

 
 

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Мега Меню на сайте" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/site-menu/add");
            }}
          />
        </div>
      </section>

      

      <section className="page-content">
    
        <UiTreeSitemMenuTable  
          list={props.siteMenu.filteredList} 
          onEditClick={(item) => {
            storeData("editSitemMenu", item);
            storeData("editSitemMenuList", props.siteMenu.list);
            navigate(`/edit/site-menu/${item.id}`);
          } } 
          onDeleteClick={(item) => {
            let _item = item;
            _item.is_del =  1;
            props.update(_item);
          } } 
          onChangeVal={(v, item) => { 
            let _item = item;
            if(v == "is_menu" ) _item.is_menu = _item.is_menu == 1 ? 0 : 1;
            if(v == "is_popular" ) _item.is_popular = _item.is_popular == 1 ? 0 : 1;
            if(v == "is_site_menu" ) _item.is_site_menu = _item.is_site_menu == 1 ? 0 : 1;
            if(v == "is_top_menu" ) _item.is_top_menu = _item.is_top_menu == 1 ? 0 : 1;
            if(v == "is_disable_url" ) _item.is_disable_url = _item.is_disable_url == 1 ? 0 : 1;
      
            props.update(_item);
          } } 

          onChangeVisible={(v, item) => {
            let _item = item;
            let f = _item.is_menu == 1 ? 0 : 1;
            _item.is_menu = f;
            _item.data = {
              id: v,
              is_menu: f
            }
            props.update(_item);
          } } 

          onChangeSort={(v, item) => {
            let _item = item;
            item.sort = v;
            props.update(_item, false);
          } } 
        />

      </section>

 


 
 
    </div>
  );
}

export default connect(
  ({  siteMenu }) => ({ 
    siteMenu,
  }),
  {
    getMenuList: getMenuListAction,
    update: updateAction, 
 
 
  }
)(MenuSiteListPage);
