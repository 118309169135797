import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getCategoryAdditionalList as getCategoryAdditionalListAction,
  update as updateAction,
} from "../../redux/modules/categoryAdditional";

import { convertImages, fromArrayListKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor"; 
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./CategoryAdditionalStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function CategoryAdditionalPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" }, 
    { sort: false, title: "Название" }, 
    { sort: false, title: "Категория для допов" }, 
    { sort: false, title: "Скрыть" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    props.getCategoryAdditionalList();
  }, []);

  useEffect(() => {
    let arr = [];
    props.categoryAdditional.list.map((item) => {
      let images =  item.storages_list.list[0] ? convertImages(item.storages_list.list[0].imgs) : null;
      item.images = images;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            storeData("editSale", item);
            navigate(`/products/additional/id/${item.data.id}`);
          },
        },
        { image: images ? Env.PUBLIC_URL + images[0] : null },
     
        { text: item.data.title },
        { text: item.data.category_title?.title },
        {
          button: item.is_active == 0 ? "eye-open" : "eye",
          onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            props.update(_item);
          },
        },
        {
          button: "delete",
          color: "red",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item.data;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.update(_item);
            }
          },
        },

      ]);
    });
    setTableListOrders(arr);
  }, [props.categoryAdditional.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список категорий доп товаров" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("sale", null);
              navigate("/products/additional/add");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
    </div>
  );
}

export default connect(
  ({ categoryAdditional }) => ({
    categoryAdditional,
  }),
  {
    getCategoryAdditionalList : getCategoryAdditionalListAction,
    update : updateAction,
  }
)(CategoryAdditionalPage);
