import React, { useEffect, useState } from "react";
import { connect } from "react-redux"; 
import { useNavigate, useParams } from "react-router-dom"; 

import UiInputText from "../../components/inputs/UiInputText"; 
import UiButtonColor from "../../components/button/UiButtonColor"; 

import { addMenu as addMenuAction } from "../../redux/modules/siteMenu";
 

import "./MenuStyle.scss";

function MenuSiteAddPage(props) {
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    isEdit: false,
    title: "",
    category: 0,
    is_menu: 1,
    is_site_menu: 0,
    description: "",
  });
  const [state, setState] = useState({
    isLoad: false,
    isDialogCrop: false,
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });
 
  useEffect(() => {}, []);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Добавить пункт меню</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={order.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>
               

                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="indigo"
                    text={  "Создать"}
                    small={true}
                    onClick={() => {
                      if (order.title != "") {
                        let _order = order;
                        props.addMenu(_order).then((res) => {
                          if (res) navigate(`/site-menu`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ siteMenu }) => ({ siteMenu }), {
  addMenu: addMenuAction
})(MenuSiteAddPage);
