import ENV from './Env.js';


export const getList = () => {
  return fetch(`${ENV.API_URL}/sales/all`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const getSaleId = (_id) => {
  return fetch(`${ENV.API_URL}/sales/get/id/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const makeSale = (_api, _data) => {
  _data['api_token'] = _api; 
  return fetch( `${ENV.API_URL}/sales/store`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateSale = (_api, _data) => {
  _data['api_token'] = _api;
  return fetch(`${ENV.API_URL}/sales/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const updateSaleItem = (_api, _data) => {
  _data['api_token'] = _api;
  return fetch(`${ENV.API_URL}/sales/item/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const addSaleItem = (_api, _data) => {
  _data['api_token'] = _api;
  return fetch(`${ENV.API_URL}/sales/item/store`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


