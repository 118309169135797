import category from './category';
import categoryAdditional from "./categoryAdditional";
import comment from './comment';

import login from './login';
import logs from './logs';

import material from './material';
import menu from './menu';
import siteMenu from './siteMenu';
import tags from './tags';

import order from './order';

import users from './users';

import sales from './sales';
import storage from './storage';
import settings from './settings';

export default {
    users,
    login,
    storage,
    siteMenu,
    material,
    menu,
 
    tags,
    order,
    comment,
    logs,
    sales,
    settings,
    category,
    categoryAdditional
}