export default {
    STORE_KEY: 'a56z0fzrNpl^2',
 

    HOST_URL: 'https://xn---102-53d0cn4bmz2d.xn--p1ai/',
    HOST_URL_CATALOG: 'https://xn---102-53d0cn4bmz2d.xn--p1ai',
    HOST_CRM_URL: 'https://opanel.xn---102-53d0cn4bmz2d.xn--p1ai/',
    API_URL: 'https://opanel.xn---102-53d0cn4bmz2d.xn--p1ai/server/public/api/v1',
    WS_URL: 'wss://194.58.98.186:443/',
    PUBLIC_URL: 'https://opanel.xn---102-53d0cn4bmz2d.xn--p1ai/server/public/uploads/',

 
    //STORAGE_URL: '',
   
    getHeaders: function () {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }
  };