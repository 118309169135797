import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedOrgRoute from "./routes/ProtectedOrgRoute";

import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import DashboardPage from "../pages/home/DashboardPage";
import ClientsPage from "../pages/clients/ClientsPage";
import ClientPage from "../pages/clients/ClientPage";
import ManagersPage from "../pages/managers/ManagersPage";

import PrintCheckPage from "../pages/home/PrintCheckPage";

import OrgsPage from "../pages/orgs/OrgsPage";
import ProductsPage from "../pages/products/ProductsPage";
import ProductsCategoryAddPage from "../pages/products/ProductsCategoryAddPage";
import ProductsCategorys from "../pages/products/ProductsCategorys";
import ProductsCategoryEditPage from "../pages/products/ProductsCategoryEditPage";

import MenuListPage from "../pages/menu/MenuListPage";
import MenuEditPage from "../pages/menu/MenuEditPage";
import MenuAddPage from "../pages/menu/MenuAddPage";

import MenuSiteListPage from "../pages/menu-site/MenuSiteListPage";
import MenuSiteEditPage from "../pages/menu-site/MenuSiteEditPage";
import MenuSiteAddPage from "../pages/menu-site/MenuSiteAddPage";



import SearchTagEditPage from "../pages/menu/tag/SearchTagEditPage";
import SearchTagAddPage from "../pages/menu/tag/SearchTagAddPage";

import HomeStaticPage from "../pages/pages/HomeStaticPage";
import HomeStaticAddPage from "../pages/pages/HomeStaticAddPage";
import HomeStaticEditPage from "../pages/pages/HomeStaticEditPage";

import MaterialsPage from "../pages/materials/MaterialsPage";
import MaterialAddPage from "../pages/materials/MaterialAddPage";

import SalesPage from "../pages/sales/SalesPage";
import SalesAddPage from "../pages/sales/SalesAddPage";
import SalesEditPage from "../pages/sales/SalesEditPage";

import CategoryAdditionalPage from "../pages/category-additional/CategoryAdditionalPage";
import CategoryAdditionalAddPage from "../pages/category-additional/CategoryAdditionalAddPage";
import CategoryAdditionalEditPage from "../pages/category-additional/CategoryAdditionalEditPage";


import BlogAddPage from "../pages/blog/BlogAddPage";
import BlogListPage from "../pages/blog/BlogListPage";

import ReviewPage from "../pages/materials/ReviewPage";

import ProductsAddPage from "../pages/products/ProductsAddPage";
import ArchivePage from "../pages/archive/ArchivePage";

import SettingPage from "../pages/setting/SettingPage";
import DeliveryZonePage from "../pages/setting/delivery/DeliveryZonePage";
import DeliveryRulePage from "../pages/setting/rules/DeliveryRulePage";
import DeliveryPaymentsPage from "../pages/setting/payments/DeliveryPaymentsPage";

import StatisticPage from "../pages/statistic/StatisticPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ClientsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/client"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ClientPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/managers"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ManagersPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/orgs"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <OrgsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/products/category/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ProductsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/site-menu"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MenuSiteListPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit/site-menu/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MenuSiteEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/site-menu/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MenuSiteAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />


      <Route
        path="/menu"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MenuListPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/edit/menu/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MenuEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/edit/menu/tag/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <SearchTagEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/menu/tag/add/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <SearchTagAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />



      <Route
        path="/menu/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MenuAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/home"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <HomeStaticPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/home/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <HomeStaticAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/home/edit/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <HomeStaticEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />



      <Route
        path="/category/all"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ProductsCategorys />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/add/category"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ProductsCategoryAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/edit/category/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ProductsCategoryEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/products/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ProductsAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/material/list"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MaterialsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/material/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MaterialAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/sales/list"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <SalesPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/sales/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <SalesAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/sales/id/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <SalesEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />


      <Route
        path="/products/additional/list"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <CategoryAdditionalPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/products/additional/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <CategoryAdditionalAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/products/additional/id/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <CategoryAdditionalEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />




      <Route
        path="/reviews"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ReviewPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />


      <Route
        path="/blog/list"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <BlogListPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/blog/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <BlogAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />


      <Route
        path="/archive"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ArchivePage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0">
              <SettingPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/setting/delivery"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0">
              <DeliveryZonePage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/setting/rules"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0">
              <DeliveryRulePage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/setting/payments"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0">
              <DeliveryPaymentsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />



      <Route
        path="/statistic"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0">
              <StatisticPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/deal/check/:id"
        element={
          <ProtectedRoute>
            <PrintCheckPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AppNavigator;
